import  { createContext } from 'react'

import { SidebarApi , COLLAPSE_SIDEBAR} from './types'

const initialState: SidebarApi = {
  collapsed: false,
}
// Define Conext
export const SidebarStateContext = createContext(initialState)
export const SidebarDispatchContext = createContext(undefined)

// Reducer
export const SidebarReducer = (state, action) => {
  switch (action.type) {
    case COLLAPSE_SIDEBAR: {
      return {
        ...state,
        collapsed: action.collapsed,
      }
    }
    
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
