import React from 'react'
import styled from 'styled-components'
// import { Spinner } from '@pancakeswap/uikit'
import {RingLoader} from 'react-spinners'
import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <RingLoader  size={300} color='#C2CCF1' />
    </Wrapper>
  )
}

export default PageLoader
