import React, {  useReducer, useContext } from 'react'
import { SidebarDispatchContext, SidebarReducer, SidebarStateContext } from './stateManager'


export const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SidebarReducer, {
    collapsed: false,
  })

  return (
    <SidebarDispatchContext.Provider value={dispatch}>
      <SidebarStateContext.Provider value={state}>{children}</SidebarStateContext.Provider>
    </SidebarDispatchContext.Provider>
  )
}

// custom hooks for when we want to use our Sidebar state
export const useSidebarStateContext = () => useContext(SidebarStateContext)

export const useSidebarDispatchContext = () => useContext(SidebarDispatchContext)
