import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Menu, SubMenu, MenuItem } from 'react-pro-sidebar'
import { HomeIcon, TradeIcon, InfoIcon, PoolIcon, FarmIcon, MoreIcon, IFOIcon, LotteryIcon } from './Icons'

const SidebarMainContent = styled.div`
  height: 90vh;
  background: #272727;
  color: #c6cacf;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  svg path {
    fill: #7a6eaa;
  }
`
function NavContent() {
  return (
    <SidebarMainContent>
      <div>
        <Menu>
          <MenuItem icon={<HomeIcon />}>
            <Link to="/" />
            Home
          </MenuItem>
        </Menu>
        <Menu>
          <SubMenu title="Trade" icon={<TradeIcon />}>
            <MenuItem>
              <Link to="/swap" />
              Exchange
            </MenuItem>
            <MenuItem>
              <Link to="/pool" />
              Liquidity
            </MenuItem>
          </SubMenu>
        </Menu>
        <Menu>
          <MenuItem icon={<FarmIcon />}>
            <Link to="/farms" />
            Farms
          </MenuItem>
        </Menu>
        <Menu>
          <MenuItem icon={<PoolIcon />}>
            <Link to="/pools" />
            Pools
          </MenuItem>
        </Menu>
        <Menu>
          <SubMenu title="GameFi" icon={<MoreIcon />}>
            <MenuItem>
              <a href="https://">Play2Earn</a>
            </MenuItem>
            <MenuItem>
              <a href="https://casino.bscgames.win">Casino</a>
            </MenuItem>
          </SubMenu>
        </Menu>
         {/* <Menu>
          <MenuItem icon={<IFOIcon />}>
            <Link to="/ifo" />
            IFO
          </MenuItem>
        </Menu>
        <Menu>
          <MenuItem icon={<LotteryIcon />}>
            <Link to="/lottery" />
            Lottery
          </MenuItem>
        </Menu>
        <Menu>
          <MenuItem icon={<InfoIcon />}>
            <a href="#">Info</a>
          </MenuItem>
        </Menu> */}
        <Menu>
          <SubMenu title="Info" icon={<InfoIcon />}>
            <MenuItem>
              <a href="mailto:info@bscgames.win">Email</a>
            </MenuItem>
            <MenuItem>
              <a href="https://t.me/bscgameswin">Telegram</a>
            </MenuItem>
            <MenuItem>
              <a href="https://twitter.com/bscgameswin">Twitter</a>
            </MenuItem>
            <MenuItem>
              <a href="https://github.com/BSC-GAMES">Github</a>
            </MenuItem>
            <MenuItem>
              <a href="https://docs.bscgames.win">Docs</a>
            </MenuItem>
          </SubMenu>
        </Menu>
      </div>
    {/*  <SidebarFooter>
     </SidebarFooter> */}
    </SidebarMainContent>
  )
}

export default NavContent
