import React from 'react'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import styled from 'styled-components'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ButtonContainer = styled.div`
  margin-right: 10px;
  .connect_wallet_button {
    background: #2b81d6;
  }
  @media (max-width: 760px) {
    .connect_wallet_button {
      font-size: 12px;
    }
  }
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <ButtonContainer>
      <Button
        onClick={onPresentConnectModal}
        {...props}
        // style={{
        //   background: '',
        // }}
        className="connect_wallet_button"
      >
        {t('Connect Wallet')}
      </Button>
    </ButtonContainer>
  )
}

export default ConnectWalletButton
