import React, { useState } from 'react'
import styled from 'styled-components'
import { ListViewIcon } from '@pancakeswap/uikit'

import { ProSidebar } from 'react-pro-sidebar'
import LogoImage from './images/logo.b59531de.png'
import SidebarMainContent from './NavContent'

const MenuContainer = styled.div``
const NavSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 3%;
  min-height: 10vh;
  background: #272727;
  & > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  .left_nav_corner {
    button {
      background: none;
      border: none;
    }
    img {
      height: 40px;
      width: auto;
    }
    svg {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
  }
`

const ContentBody = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  overflow: auto;
`

const ChildrenContent = styled.div`
  width: 85%;
  height: 90vh; 
  overflow: auto;
  @media (max-width: 760px) {
    width: 100%
  }
`

const CustomMenuContainer = ({ children, userMenu, globalMenu }) => {
  const [collapse, setCollapse] = useState(false)
  return (
    <MenuContainer>
      <NavSection>
        <div className="left_nav_corner">
          <button type="button" onClick={() => setCollapse((state) => !state)}>
            <ListViewIcon />
          </button>
          <img src={LogoImage} alt="logo" />
        </div>

        <div className="right_nav_corner">
          {userMenu}
          {globalMenu}
        </div>
        {/* <button
          onClick={() => {
            dispatch(async () => toggleTheme())
          }}
          type="button"
        >
          Toggle Theme
        </button>
        <button type="button" onClick={() => setCollapse((state) => !state)}>
          Toggle Sidebar
        </button> */}
      </NavSection>
      <ContentBody>
        <ProSidebar collapsed={collapse} width="15%">
          <SidebarMainContent />
        </ProSidebar>
        <ChildrenContent>{children}</ChildrenContent>
      </ContentBody>
    </MenuContainer>
  )
}

export default CustomMenuContainer
