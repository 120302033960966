import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
   {
    pid: 0,
    lpSymbol: 'yBSCG',
    lpAddresses: {
      97: '',
      56: '',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'yBSCG-BNB LP',
    lpAddresses: {
      97: '',
      56: '',
    },
    token: tokens.yBSCG,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
    {
    pid: 4,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'CAKE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
  },
  {
    pid: 6,
    lpSymbol: 'TKP-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x32ecb89ed494644448ca297dfadb96c7a1809178',
    },
    token: tokens.tkp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 7,
    lpSymbol: 'TKP-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xbce410248c6290fb06989a0d94d607324ebe4cec',
    },
    token: tokens.tkp,
    quoteToken: tokens.busd,
  },
  
]

export default farms
