export interface SidebarApi {
    collapsed: boolean;
    rtl?: boolean;
    toggled?: string;
    // onToggle?: (value:boolean)=> {};
    breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    width?: number | string;
    collapsedWidth?: number | string
}
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR'
